<style lang="sass" scoped>

</style>
<template lang="pug">
div.bg-light
  //- form.form(@submit.prevent='submit')
  .container-lg
    .bg-white.shadow-sm.p-3(style='font-size: 12px')
      h1 보낸 메시지들
        router-link.float-right.btn.btn-dark.shadow-sm.py-3.px-4(:to='`/property/${property_id}/views/message/new`'): strong 새로운 그룹 DM 보내기
      p {{rows.length}}건
      .list-group
        .list-group-item(v-for='row in rows')
          pre {{row}}

    //- .col

      .bg-white.p-2.mt-2.border(style='font-size: 12px')

</template>

<script>

export default {
  name: 'index',
  props: ['property_id', 'sf_id', 'order_id'],
  components: {

  },
  computed: {

  },
  watch: {

  },
  data() {
    return {
      rows: [],
    }
  },
  async mounted() {
    this.fetch_message_group()
  },
  methods: {
    async fetch_message_group() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/message-groups`)
        if (r?.data?.message != 'ok') throw new Error('가져오기 실패')

        this.rows = r.data.rows
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
  },
}
</script>
